<template>
  <div id="app">
    <main>
      <HomePage />
      <ScHub />
      <AiHub />
      <AboutUs />
      <OurImpact />
      <ForMs />
      <ContactUs />
      <SubscribePage />
      <!-- Add more sections here -->
    </main>
    <AppFooter />
  </div>
</template>
<script>
import AboutUs from './components/AboutUs.vue';
import HomePage from './components/HomePage.vue';
import OurImpact from './components/OurImpact.vue';
import ScHub from './components/ScHub.vue'
import AiHub from './components/AiHub.vue'
import ContactUs from './components/ContactUs.vue'
import SubscribePage from './components/SubscribePage.vue'
import '@fortawesome/fontawesome-free/css/all.min.css';
import ForMs from './components/ForMs.vue'
import AppFooter from './components/AppFooter.vue';


export default {
  components: {
    HomePage,
    ScHub,
    AiHub,
    AboutUs,
    OurImpact,
    ForMs,
    ContactUs,
    SubscribePage,
    AppFooter
  }
}
</script>

<style>
main {
  margin: 0px;
  background-color: rgb(250, 250, 250);
}
</style>
