<template>
  <div class="combined-component">
    <!-- Image Section -->
    <div class="image-container">
      <img class="frame-image" :src="imageSrc" alt="Main Image" />
      <!-- Chat Symbol -->
      <div class="chat-symbol">
        <div class="chat-bubble">
          <div class="chat-text">
            <span class="quote">“</span>
            <span>__________ ___________ ___________</span>
            <span class="quote">”</span>
          </div>
          <div class="chat-tail"></div>
        </div>
      </div>
    </div>

    <!-- Form Section -->
    <h1 class="subscribe-to-product">Subscribe to product updates</h1>
    <div class="product-updates-input">
      <div class="email-input">
        <!-- Input Field with Placeholder -->
        <input
          type="text"
          v-model="emailPhone"
          placeholder="Enter your Email/phone"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <div class="submit-button" @click="handleSubmit">Submit</div>
      </div>
    </div>

    <!-- Thank you message -->
    <h2 class="thank-you-text" v-if="showThankYouText">You Subscribed to DMPE product updates</h2>

    <!-- Hidden form to bypass CORS -->
    <form
      ref="hiddenForm"
      action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdQRqvJ3EHFU_4e3lXoIpEl829CFk6ZUgILJrA3khd-_PFW-g/formResponse"
      method="POST"
      target="hidden_iframe"
      style="display: none;"
    >
      <input
        type="hidden"
        name="entry.1106769114"
        :value="emailPhone"
      />
    </form>
    <iframe name="hidden_iframe" style="display:none;"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageSrc: require('@/assets/group-1597883396.svg'), // Initial image source
      finalImageSrc: require('@/assets/complated subcision envelope design.png'), // Final image source
      showThankYouText: false, // Flag for displaying thank you message
      emailPhone: '', // Model for email/phone input
    };
  },
  methods: {
    handleSubmit() {
      // Validate input before proceeding (optional)
      if (this.emailPhone.trim() === '') {
        alert('Please enter your email or phone.');
        return;
      }

      // Hide the form and show the thank you text
      this.showThankYouText = true;

      // Hide the form elements
      document.querySelector('.subscribe-to-product').style.display = 'none';
      document.querySelector('.product-updates-input').style.display = 'none';

      // Change the image source immediately
      this.imageSrc = this.finalImageSrc;

      // Add class to disable hover effect
      document.querySelector('.combined-component').classList.add('no-hover');

      // Submit hidden form to Google Forms
      this.$refs.hiddenForm.submit();
    },
    handleFocus(event) {
      event.target.classList.add('focused');
    },
    handleBlur(event) {
      event.target.classList.remove('focused');
    },
  },
};
</script>

<style scoped>
/* Combined Component */
.combined-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

/* Image Container */
.image-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

/* Image */
.frame-image {
  width: 400px;
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  transition: none; /* No transition effect */
}

/* Form Section */
.subscribe-to-product {
  font-size: 30px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.email-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Input Field */
.email-input input {
  width: 450px;
  height: 40px;
  opacity: 0.25;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  padding: 0 10px; /* Adjust padding for spacing */
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  transition: opacity 0.3s ease; /* Smooth opacity transition */
}

/* Placeholder styling */
.email-input input::placeholder {
  color: #30353D; /* Placeholder color */
  font-size: 24px;
}

.email-input input:hover::placeholder,
.email-input input:focus::placeholder {
  opacity: 0;
}

/* Submit Button */
.submit-button {
  border-radius: 5px;
  background-color: tomato;
  padding: 10px 20px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

/* Chat Symbol */
.chat-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  width: 90px;
  height: 30px;
  background-color: rgb(230,75,40,89);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; /* Smooth transitions */
}

.chat-bubble {
  position: relative;
  background-color: rgb(230,75,40,89);
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.chat-text {
  display: flex;
  align-items: center;
}

.quote {
  font-size: 20px;
  font-weight: bold;
  margin: 0 5px;
}

.chat-tail {
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(230,75,40,89);
}

/* Hover effect */
.image-container:hover .chat-symbol {
  opacity: 1;
  transform: translate(-98%, -85px) rotate(-15deg);
}

/* No hover effect after submit */
.no-hover .image-container:hover .chat-symbol {
  opacity: 0;
  transform: translate(-50%, -50%) rotate(-15deg);
}

/* Thank You Text */
.thank-you-text {
  font-size: 30px;
  color: #30353D;
  font-family: Poppins, sans-serif;
  text-align: center;
  margin-top: 20px;
}

/* Responsive */
@media screen and (max-width: 450px) {
  .subscribe-to-product {
    font-size: 17px;
  }

  .submit-button {
    font-size: 14px;
  }

  .email-input input {
    font-size: 14px;
  }
}
</style>
