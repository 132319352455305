<template>
  <div class="text-wrapper">
      <span class="background-text">Products</span>
      <span class="foreground-text"> P r o d u c t s </span>
    </div>
  <div class="hover-wrapper">
    <!-- Circle Container -->
    <div class="circle-container">
      <div class="inner-circle"></div>
      <img class="circle-logo" :src="logoSrc" alt="Logo"> <!-- Image appears on hover -->
    </div>

    <!-- Text Container -->
    <div class="text-container">
      <div class="heading">Sc Hub</div>
      <div class="description">
        SC Hub is a cutting-edge platform designed to <br>
        streamline the development and integration of <br>
        artificial intelligence solutions. Our product offers <br>
        a comprehensive suite of tools and services,
      </div>
      <div class="line"></div>
      <button class="explore-button">Explore SC Hub</button>
    </div>
  </div>
</template>

  
  <script>
  export default {
    data() {
      return {
        logoSrc: require('@/assets/Sc hub.png') // Update this path according to your project structure
      };
    }
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
  
  /* Main container */
  .hover-wrapper {
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  padding-top: 80px; 
  background-color: white;
  }

  .hover-wrapper:hover{
    background-color: rgb(250, 250, 250);
  }
  
  .text-wrapper {
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  padding-top: 40px; 
}

.background-text {
  font-size: 120px; 
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #d3d3d3; 
  position: absolute; 
  z-index: 1; 
  transform: scaleX(2) scaleY(0.6); 
  transform-origin: center; 
  background: linear-gradient(to bottom, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.1) 60%, rgba(211, 211, 211, 0) 100%);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent; 
  }


.foreground-text {
  font-size: 40px; 
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #333; 
  z-index: 2; 
  letter-spacing: 3px;
  padding-bottom: 0px;;
}
  
  /* Circle Container */
  .circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: transform 0.5s ease;
    width: 500px; /* Container size */
    height: 500px; /* Container size */
  }
  
  /* Inner Circle */
  .inner-circle {
    width: 500px; /* Size of the circle */
    height: 500px; /* Size of the circle */
    border-radius: 50%;
    background-color: rgba(211, 211, 211, 0.3); /* Lighter gray with transparency */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease;
    opacity: 1; /* Initially visible */
    background-image: url('@/assets/sc_hub_before_hover.png'); /* Path to your image */
    background-size: 60% 60%; /* Reduced size of the image */
    background-position: center center; /* Center the image */
    background-repeat: no-repeat; /* Prevent image from repeating */
  }
  
  /* Circle Logo (Image) */
  .circle-logo {
    width: 0; /* Initial width */
    height: 0; /* Initial height */
    object-fit: contain; /* Ensure aspect ratio */
    border-radius: 45%; /* Keep it within the circle */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease, transform 0.5s ease; /* Smooth appearance */
    position: absolute;
  }
  
  /* Text Container */
  .text-container {
    position: absolute;
    width: 600px;
    height: 223px;
    top: 307px;
    gap: 20px;
    right: 0%; /* Move container to the left */
    top: 50%;
    transform: translateY(-50%);
    padding: 30px; /* Increased padding for more space */
    color: #30353D;
    text-align: justify;
    display: none; /* Hide initially */
    z-index: 1; /* Ensure text appears above the image */
  }
  
  /* Heading */
  .heading {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6em; /* Adjust if needed */
    font-weight: 600; /* Make it a bit bolder */
    line-height: 80px;
    text-align: left;
    color: #2C2C2C; /* Darker color for the text */
    margin-bottom: 10px;
  }
  
  
  /* Description */
  .description {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
    text-decoration: #30353D;
    margin-bottom: 20px;
  }
  
  /* Line */
  .line {
    width: 100%; /* Reduced width */
    height: 2px;
    background-color: #DCDCDC;
    margin: 20px 0; /* Adjust margin to move the line up */
    border: 0.5px;
  }
  
  
  /* Explore Button */
  .explore-button {
    background-color: #E64B28;
    color: white;
    border: none;
    border-radius: 6px; /* Increased border-radius for a rounder button */
    padding: 15px 30px; /* Increased padding for a larger button */
    font-size: 18px; /* Increased font size */
    cursor: pointer;
    margin-top: 20px; /* Moves the button lower */
  }
  
  
  /* Hover State */
  .hover-wrapper:hover .circle-container {
    transform: translateX(-500px); /* Moves the circle to the left */
  }
  
  .hover-wrapper:hover .inner-circle {
    opacity: 0; /* Hide circle on hover */
  }
  
  .hover-wrapper:hover .circle-logo {
    opacity: 1; /* Show image on hover */
    width: 800px; /* Increased width after hover */
    height: 800px; /* Increased height after hover */
    left: -30%; /* Move image to the right */
    transform: translateX(-10%); /* Adjust to fit your needs */
  }
  
  .hover-wrapper:hover .text-container {
    display: block; /* Display text on hover */
  }
  
  </style>