<template>
  <div class="block">
    <div class="container1">
      <div class="content1">
        <h1>Our Impact</h1>
        <img src="@/assets/box.png" alt="img" class="img1" />
        <img src="@/assets/box1.png" alt="img" class="img2" />
        <p class="hidden-paragraph">
          Our Commitment Translates into maximum ROI,
          <br />
          guaranteeing the quality of traffic that has higher
          <br />
          potential in terms of conversion rates and becoming
          <br />
          a community of loyal customers. we excel in brand
          <br />
          building, accelerating your reach, and contributing to
          <br />
          long-term sustainability. With us, you experience
          <br />
          brand awareness and visibility that extends beyond
          <br />
          trends and algorithm updates. Our effects and results
          <br />
          are not just momentary successes, they stand
          <br />
          resilient against the tides of ever-changing digital
          <br />
          landscapes. We see ourselves as contributors to your
          <br />
          digital growth and success stories.
        </p>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: "TextImageReveal"
  };
  </script>

  <style scoped>

.block{
  height: 600px;
}
  .container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column; 
  margin: 60px 20px; 
  padding-top: 40px;
  height: 100%; 
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.content1 {
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 400px; 
  position: relative; 
  transition: gap 1.5s ease-out; 
   font-family: 'Poppins', sans-serif;
}

img {
  max-width: 500px; /* Increased image size */
  width: 100%;
  height: auto;
  transition: opacity 1.5s ease-out, transform 1.5s ease-out; 
  position: absolute; 
}

.img1 {
  opacity: 1; 
  transform: translateX(0); 
}

.img2 {
  opacity: 0; 
  transform: translateX(0); 
}

h1 {
  color: gray;
  padding-bottom: 80px;
  margin: 0;
  opacity: 1; 
  transform: translate(0, 0); 
  transition: transform 1.5s ease-out, color 0.3s ease-in-out; 
  font-size: 3em; 
  font-family: 'Poppins', sans-serif;
}

.hidden-paragraph {  
  opacity: 0; 
  transform: translateX(100px);  
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-size: 1.2rem; 
  text-align: justify;  
  font-family: 'Poppins', sans-serif;
}

.container1:hover {
  flex-direction: row; 
  align-items: center; 
  justify-content: center;
}

.container1:hover .content1 {
  gap: 10px; 
}

.container1:hover h1 {
  color: black;
  transform: translate(350px, -200px); 
}

.container1:hover .img1 {
  opacity: 0; 
  transform: translateX(-400px); 
}

.container1:hover .img2 {
  opacity: 1; 
  transform: translateX(-400px); 
}

.container1:hover .hidden-paragraph {  
  opacity: 1;   
  transform: translateX(50px); 
  text-align: justify; 
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
}
  </style>
  