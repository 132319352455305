<template>
  <div class="container">
    <!-- Left Side: Contact Information -->
    <div class="contact-info">
      <h2>Or else reach out to us at</h2>
      <div class="contact-item">
        <i class="fas fa-envelope"></i>
        <a id="email">support@dmpe.co</a>
        <button class="copy-btn" @click="copyToClipboard('email')">
          <i class="fas fa-copy"></i>
        </button>
      </div>
      <div class="contact-item">
        <i class="fas fa-phone"></i>
        <a id="phone">080 - 2504 3269</a>
        <button class="copy-btn" @click="copyToClipboard('phone')">
          <i class="fas fa-copy"></i>
        </button>
      </div>
      <div class="contact-item">
        <i class="fas fa-map-marker-alt"></i>
        <span id="address">
          03A - 108, 3rd floor, Roshni Tech Hub, PFS Club House, Marathahalli Main Rd,
          near WeWork, Anand Nagar, Aswath Nagar, Chinnapanna Halli, Bengaluru, Karnataka 560037
        </span>
        <button class="copy-btn" @click="copyToClipboard('address')">
          <i class="fas fa-copy"></i>
        </button>
      </div>

      <!-- Social Media Icons -->
      <div class="social-media">
        <a href="https://github.com" class="social-icon git" aria-label="GitHub"><i class="fab fa-github"></i></a>
        <a href="https://www.instagram.com" class="social-icon insta" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
        <a href="https://www.linkedin.com" class="social-icon linkedin" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
        <a href="https://twitter.com" class="social-icon tweet" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
        <a href="https://www.youtube.com" class="social-icon yt" aria-label="YouTube"><i class="fab fa-youtube"></i></a>
        <a href="https://discord.com" class="social-icon discord" aria-label="Discord"><i class="fab fa-discord"></i></a> 
      </div>
    </div>

    <!-- Right Side: Leaflet Map and Text -->
    <div class="map-text-container">
      <div class="map-container">
        <l-map :zoom="zoom" :center="center" style="height: 100%; width: 100%;">
          <l-tile-layer :url="tileUrl" />
          <l-marker :lat-lng="markerPosition">
            <l-popup>
              Address: 03A - 108, 3rd floor, Roshni Tech Hub, PFS Club House, Marathahalli Main Rd, near WeWork, Anand Nagar,
              Aswath Nagar, Chinnapanna Halli, Bengaluru, Karnataka 560037
            </l-popup>
          </l-marker>
        </l-map>
      </div>

      <!-- Text below the map -->
      <div class="text">
        <h1 class="texth1">We will get in touch with you as soon as you reach us.</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  components: {
    LMap, LTileLayer, LMarker, LPopup,
  },
  data() {
    return {
      center: [12.9716, 77.5946], // Coordinates for Bangalore
      zoom: 13,
      markerPosition: [12.9716, 77.5946], // Coordinates for the address
      tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };
  },
  methods: {
    copyToClipboard(elementId) {
      const element = document.getElementById(elementId);
      const textToCopy = element.textContent || element.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
        alert("Copied to clipboard!");
      }).catch(err => {
        console.error("Failed to copy: ", err);
      });
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css'); /* Ensure Font Awesome is imported */

.container {
  display: flex;
  justify-content: space-between; /* Space between contact info and map */
  align-items: flex-start; /* Align items to the top */
  flex-wrap: wrap; /* Wrap content on smaller screens */
  padding: 20px; /* Padding for better spacing */
  overflow: hidden;
}

.contact-info {
  width: 45%; /* Takes 45% of the container width */
  padding: 20px;
  font-size: 1.2em; /* Increase the base font size */
}

.contact-info h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em; /* Increase font size for the heading */
  color: rgba(0, 0, 0, 0.641);
}

.contact-item {
  margin-bottom: 20px; /* Increase space between items */
  display: flex;
  align-items: center;
  font-family: 'Poppins',sans-serif;
  padding-top: 30px;
  color: rgba(101, 93, 93, 0.91); /* Initial color */
  position: relative; /* Ensure that the copy-btn is positioned relative to the contact item */
  font-size: 1.1em; /* Increase font size for contact items */
  transition: color 0.3s ease, transform 0.3s ease; /* Transition for color and transform */
}

.contact-item:hover {
  color: #000; 
}

.contact-item i {
  margin-right: 15px; 
  font-size: 1.5em; 
}

.contact-item a, .contact-item span {
  margin-left: 15px; 
  font-size: 1.1em; 
}

.copy-btn {
  margin-left: 10px; /* Adjust margin for better spacing */
  background-color: transparent; 
  color: #30353D; /* Icon color */
  cursor: pointer;
  font-size: 0.9em; /* Adjust font size */
  border: none; 
  display: none; /* Hidden by default */
  align-items: center;
}

.contact-item:hover .copy-btn {
  display: inline-flex; /* Show the button when the contact item is hovered */
}

.copy-btn:hover {
  color: orangered; 
}

.social-media {
  display: flex;
  gap: 40px; /* Space between icons */
  margin-top: 50px; 
  margin-left: 60px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Width of the icon */
  height: 40px; /* Height of the icon */
  border: 2px solid #333; /* Initial border color */
  border-radius: 50%; /* Circular border */
  color: #848484; /* Icon color */
  font-size: 1.2em; /* Icon size */
  transition: border-color 0.3s ease, color 0.3s ease; 
}

.social-icon.git:hover {
  border-color: black; /* Border color for GitHub  */
  color: black; /* Icon color for GitHub  */
}

.social-icon.insta:hover {
  border-color: rgb(228, 94, 203); /* Border color for Instagram */
  color: rgb(228, 94, 203);  /* Icon color for Instagram */
}

.social-icon.linkedin:hover {
  border-color: blue; /* Border color for LinkedIn  */
  color: blue; /* Icon color for LinkedIn  */
}

.social-icon.tweet:hover {
  border-color: black; /* Border color for Twitter  */
  color: black; /* Icon color for Twitter  */
}

.social-icon.yt:hover {
  border-color: red; /* Border color for YouTube */
  color: red; /* Icon color for YouTube  */
}

.social-icon.discord:hover {
  border-color: #5865F2; /* Discord's color  */
  color: #5865F2; /* Discord icon color  */
}


.map-text-container {
  width: 45%; 
  display: flex;
  flex-direction: column; 
  align-items: center;
 margin-top: 150px;
}

.map-container {
  width: 100%; 
  height: 380px; 
  margin-bottom: 20px; 
  filter: grayscale(100%);
}

.map-container:hover {
  filter: grayscale(0%);
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-top: 10px;
}

.texth1 {
  font-size: 22px;
  font-family: 'Poppins',sans-serif;
  color: rgba(124, 115, 115, 0.75); 
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; 
    align-items: center; 
  }

  .contact-info, .map-text-container {
    width: 100%; 
    padding: 10px;
}
.map-container {
    height: 200px; 
  }
}
</style>