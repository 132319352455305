<template>
  <footer>
    <div class="footer-content">
      <!-- First Block with Image and Links -->
      <div class="first-block">
        <img
          class="title-image"
          loading="lazy"
          alt="Product Title"
          src="@/assets/group-1321319318@2x.png"
        />
        <div class="links-container">
          <div class="explore-links">
            <div class="head">Explore</div>
            <div class="link">What’s New</div>
            <div class="link">Careers</div>
            <div class="link">Payments</div>
            <div class="link">Help & Support</div>
            <div class="link">Contact Us</div>
          </div>
          <div class="support-links">
            <div class="head">Must Read</div>
            <div class="link">Blogs</div>
            <div class="link">News</div>
            <div class="link">Press releases</div>
          </div>
          <div class="policy-links">
            <div class="head">Privacy Policy</div>
            <div class="link">Cookie Policy</div>
            <div class="link">Content Policy</div>
            <div class="link">Refund Policy</div>
            <div class="link">Terms & Conditions</div>
            <div class="link">Safety Guidelines</div>
            <div class="link">Brand Guidelines</div>
          </div>
        </div>
      </div>

      <!-- Product Logos Section -->
      <div class="pro-title"> Our Products</div>
      <div class="product-logos">
        <div class="logo">
          <h1 class="logo-title">DAH.</h1>
          <b class="logo-subtitle">DMPE AI Hub</b>
        </div>
        <div class="logo">
          <h1 class="logo-title">DSCH.</h1>
          <b class="logo-subtitle">DMPE Social Connect Hub</b>
        </div>
        <div class="logo">
          <h1 class="logo-title">DWT.</h1>
          <b class="logo-subtitle">DMPE Website Traffic</b>
        </div>
      </div>

      <!-- Horizontal Line -->
      <div class="divider"></div>

      <!-- AI Products Section -->
      <div class="ai-products">
        <div class="ai-products-title">DMPE AI Hub</div>
        <div class="ai-products-list">
          <div class="ai-product">AI Writer</div>
          <div class="ai-product">AI Image</div>
          <div class="ai-product">AI Code</div>
          <div class="ai-product">AI Voice</div>
          <div class="ai-product">AI Video</div>
        </div>
      </div>

      <!-- Horizontal Line -->
      <div class="divider"></div>

      <!-- Traffic Sources Section -->
      <div class="traffic-sources">
        <div class="traffic-title">DMPE Traffic</div>
        <div class="traffic-types">
          <div class="traffic-type">Direct Traffic</div>
          <div class="traffic-type">Organic Search Traffic</div>
          <div class="traffic-type">Organic Social Traffic</div>
          <div class="traffic-type">E-mail Traffic</div>
          <div class="traffic-type">Referral Traffic</div>
          <div class="traffic-type">AI-Driven Traffic</div>
        </div>
      </div>

      <!-- Horizontal Line -->
      <div class="divider"></div>

      <!-- Footer Shape with Text Overlay -->
      <div class="footer-shape-container">
        <div class="footer-shape">
          <img class="subtract-icon" loading="lazy" alt="Subtract Icon" src="@/assets/subtract.png" />
        </div>
        <div class="footer-text-overlay">
          <h1 class="h1">All rights reserved @dmpe.co</h1>
        </div>
      </div>
    </div>
  </footer>
</template>


<style scoped>
.footer-content {
  display: flex;
  flex-direction: column;
  background-color: #000; /* Background set to black */
  color: white; /* Text color set to white */
  width:100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.first-block {
  display: flex;
  justify-content: flex-start; /* Aligns content to the start */
  width: 100%;
  margin-bottom: 80px; /* Add 106px gap between first-block and product-logos */
}

.title-image {
  width: 180px;
  height: 84.73px;

  /* Adjusting the position */
  margin-top: 120.2px;  /* Moves the image down */
  margin-left: 60px; /* Moves the image to the right */
}

.links-container {
  display: flex;
  justify-content: space-between;
  gap: 200px; /* Add space between the different link sections */
  width: 815px; 
  height: 307px; /* Reduce width to allow more space for alignment */
  margin-left: 165px;
  margin-top: 42px;
}

.explore-links {
  display: flex;
  flex-direction: column;
  width: 127px;
  height: 184px;
  top: 34.3px;
  gap: 0px;
  opacity: 0px;
}

.head {
  color: #666565; /* Set text color */
  font-family: 'Poppins', sans-serif; /* Set font family */
  font-size: 18px; /* Set font size */
  font-weight: 500; /* Set font weight */
  line-height: 27px; /* Set line height */
  letter-spacing: 0.03em; /* Set letter spacing */
  text-align: left; /* Align text to the left */
  margin-bottom: 10px; /* Add some space below the header */
}

.support-links {
  display: flex;
  flex-direction: column;
  margin-left: -40px; /* Move support-links a little further to the left */
}

.policy-links {
  display: flex;
  flex-direction: column;
  margin-left: -60px; /* Move policy-links even more to the left */
}

.link {
  font-family: 'Poppins', sans-serif; /* Set font family */
  font-size: 16px; /* Set font size */
  font-weight: 500; /* Set font weight */
  line-height: 24px; /* Set line height */
  letter-spacing: 0.03em; /* Set letter spacing */
  text-align: left; /* Align text to the left */
  color: white; /* Ensure the text color remains white */
  margin-bottom: 10px; /* Space between links */
}

.product-logos {
  display: flex;
  margin: 20px 0;
  gap: 275px;
  margin-bottom: 10px;
}

.pro-title {
  width: 109px;
  margin-left: 60px;
  gap: 0px;
  font-family: Josefin Sans; /* Set font family */
  font-size: 18px; /* Set font size */
  font-weight: 500;
  letter-spacing: 0.03em; /* Set letter spacing */
  text-align: left; /* Align text to the left */
  color: #666565; 
}

.logo-title {
  font-family: 'Poppins', sans-serif;
  font-size: 80px;
  font-weight: 700;
  margin-left: 60px;
  color: white;
  margin-bottom: 10px;
  margin-top: 0px;
}

.logo-subtitle {
  display: flex;
  width: 100%;
  left: 120px;
  margin: 20px 0;
  gap: 150px;
  font-weight: normal;
  color: white;
  margin-left: 90px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 20px 0;
}

.ai-products,
.traffic-sources {
  margin-bottom: 20px;
}

.ai-products-title,
.traffic-title {
  font-family: 'Poppins', sans-serif;
  color: #666565;
  margin-bottom: 10px;
  margin-left: 60px;
}

.ai-products-list {
  display: flex;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  gap: 200px;
  margin-left: 50px;
}

.traffic-types {
  display: flex;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  gap: 90px;
  margin-left: 50px;
}

.ai-product,
.traffic-type {
  margin: 0 10px;
}

.footer-shape-container {
  position: relative;
  width:1087.84px ;
  height: 185.62px;
  margin-top: 90px;
  margin-left: 100px;
}

.footer-shape {
  position: relative;
  width: 100%;
}

.subtract-icon {
  width: 110%;
  margin-bottom: 10px;
  margin-left: 40px;
}

.footer-text-overlay {
  position: absolute;
  bottom: 10px;
  left: 59%;
  transform: translateX(-50%);
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.h1{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #AAAAAA;
}
</style>
