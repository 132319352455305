<template>
  <div class="main">
    <div>
      <header>
        <nav class="navbar">
          <div class="navbar-icon">
            <!-- Replace with your icon -->
            <img src="@/assets/mainlogo.png" alt="Icon" />
          </div>
          <div class="navbar-links">
            <a
              href="#HomePage.vue"
              :class="{ active: activeLink === 'home' }"
              @click="setActiveLink('home')"
              >Home</a
            >
            <a
              href="#about"
              :class="{ active: activeLink === 'products' }"
              @click="setActiveLink('products')"
              >Products</a
            >
            <a
              href="#AboutUS.vue"
              :class="{ active: activeLink === 'about' }"
              @click="setActiveLink('about')"
              >About Us</a
            >
            <a
              href="#contact"
              :class="{ active: activeLink === 'contact' }"
              @click="setActiveLink('contact')"
              >Contact Us</a
            >
          </div>
        </nav>
      </header>

      <img
        src="@/assets/globe background circle.png"
        alt="Globe Background"
        class="globe-background"
      />

      <img src="@/assets/line.png" alt="Dashed Overlay" class="overlay" />

      <div class="circle">
        <!-- Circle without tabs inside -->
      </div>

      <div class="tabs">
        <a href="#tab1" class="tab-link tab-link1">
          <img src="@/assets/aihub.png" alt="Icon 1" /> AI Hub
        </a>
        <a href="#tab2" class="tab-link tab-link2">
          <img src="@/assets/Socialconnect.png" alt="Icon 2" /> Web Traffic
        </a>
        <a href="#tab3" class="tab-link tab-link3">
          <img src="@/assets/webtraffic.png" alt="Icon 3" /> Social Connect
        </a>
      </div>

      <div class="text-container">
        <div>
          <h1 class="line1">Reach your place in the</h1>
        </div>
        <div>
          <h1 class="line2">Digital Space</h1>
        </div>
        <div>
          <h4 class="line3">
            If you are here! you know more is not more anymore<br />
            don't worry we too know that
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      activeLink: "home", // Default active link
    };
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link; // Set the active link
    },
  },
};
</script>

<style scoped>
body {
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: rgba(251, 251, 251, 0.911);
  overflow: hidden;
  position: relative;
}

header {
  position: relative ;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  margin:0;
}
.main {
  height: 600px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0);
  backdrop-filter: blur(10px);
  margin:0;
}

.navbar-icon {
  margin-left: 155px; /* Move the icon to the right */
}

.navbar-icon img {
  height: 40px;
}


.navbar-links {
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 18px;;
  padding-right: 20px;
}

.navbar-links a {
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  margin-left: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover {
  color: rgb(230, 63, 63);
  border-bottom: 2px solid rgb(230, 75, 40); /* Add this line for underline effect */
  transition: color 0.3s ease, border-bottom 0.3s ease; /* Updated transition */
  border-radius: 0px;
}

.navbar-links a.active {
  color: rgb(230, 63, 63); /* Color for the active link */
  border-bottom: 2px solid rgb(230, 75, 40); /* Underline effect */
  font-weight: 600; /* Optional: Make the active link bold */
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-radius: 0px;
}


.content {
  margin-top: 60px;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}

.globe-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 90vh;
  object-fit: cover;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 40%;
  left: 69%;
  width: 470px;
  height: 200px;
  transform: translate(-50%, -50%);
  z-index: 5;
  pointer-events: none;
}

.circle {
  width: 400px; /* Equal width and height for a perfect circle */
  height: 400px;
  top: 40%;
  left: 57%;
  border-radius: 50%; /* Makes the shape a circle */
  background: 
    url('@/assets/continents.png') repeat-x, /* PNG image repeating horizontally */
    linear-gradient(to bottom, rgb(230, 75, 40), rgba(255, 255, 255, 0.7)); /* Gradient underneath */
  background-size: auto 100%; /* Maintain original height and auto width for horizontal repeat */
  background-position: 0 center; /* Start the image from the left */
  background-blend-mode: normal; /* Keep gradient underneath */
  position: relative; /* For absolute positioning of the image */
  overflow: hidden; 
  animation: moveBackgroundSmooth 40s linear infinite; /* Smooth animation for background image */
  z-index: 4;
  position:relative;
}

@keyframes moveBackgroundSmooth {
  0% {
    background-position: 0 center; /* Start position */
  }
  100% {
    background-position: -640px center; /* Move background image to the left */
  }
}




.tabs {
  position: relative;
  width: 320px;
  height: 320px;
  left: 1001px;
  top: 70px;
  z-index: 4;
  font-family: "Poppins", sans-serif;
}

.tab-link {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: Center;
  padding: 10px 35px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  cursor: pointer;
  font-size: px;
  color: black;
  gap: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 1);
  border: black;
  z-index: 2;
}

.tab-link img {
  width: 25px;
  height: 30px;
}

/* Positioning the tabs on the circle */
.tab-link1 {
  top: -95%;
  left: -50%;
  transform: translateX(-50%);
}

.tab-link2 {
  top: -120%;
  right: 0%;
  transform: translateY(-50%);
}

.tab-link3 {
  top: -50%;
  left: 25%;
  transform: translateX(-50%);
  width: 220px;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 600px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.text-container div {
  color: rgb(0, 0, 0);
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: center;
}

.line1 {
  color: rgb(0, 0, 0);
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  line-height: 1.2;
  text-align: left;
}

.line2 {
  color: rgb(230, 75, 40, 89);
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  line-height: 1.2;
  text-align: left;
  
}

.line3 {
  color: rgb(156, 154, 154);
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  text-align: left;
}

.text-container .line2 {
  position: relative;
  background: linear-gradient(to right, rgb(230, 75, 40), #f2d6a1); /* Example gradient colors */
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text; 
  -webkit-text-fill-color: transparent;
  animation: gradientShift 3s ease-in-out infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

</style>
