<template>
    <!-- Keep your current form structure -->
    <div class="text-wrapper">
      <span class="background-text">Contact Us</span>
      <span class="foreground-text"> C o n t a c t   U s </span>
    </div>

     <div class="align">
    <div v-if="!submitted">
      <form @submit.prevent="submitForm">
        <!-- Form structure as is -->
        <div class="form-row">
          <label for="name">My name is</label>
          <input
            type="text"
            id="name"
            v-model="formData.name"
            placeholder="Enter here"
          />
        </div>
        <div class="form-row">
          <label for="Company">I am working with/as </label>
          <input
            type="text"
            id="Company"
            v-model="formData.company"
            placeholder="Company name / independent"
          />
        </div>

        <!-- Options and phone number as is -->
        <div class="form-row side-heading"><b>& want to know more about</b></div>
        <div class="form-row button-row">
          <button
            type="button"
            v-for="option in options"
            :key="option"
            @click="handleOptionClick(option)"
            :class="{ 'selected': selectedOption === option }"
          >
            {{ option }}
          </button>
        </div>

        <div class="form-row">
          <label for="phone">Phone Number</label>
          <div style="display: flex; flex: 2; gap: 10px;">
            <select
              class="country-code"
              id="country-code"
              v-model="formData.countryCode"
            >
              <option value="+1">+1</option>
              <option value="+44">+44</option>
              <option value="+91">+91</option>
              <option value="+81">+81</option>
            </select>
            <input
              type="text"
              id="phno"
              v-model="formData.phone"
              placeholder="Phone number"
            />
          </div>
        </div>

        <div class="submit-row">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>

    <!-- Submitted content remains the same -->
    <div v-else class="submitted-content">
      <img
        src="../assets/contact details submitted.png"
        alt="Submitted"
        class="submitted-image"
      />
      <div class="texts-wrapper">
        <p class="text1">Thanks for initiating, we will be in touch shortly</p>
        <p class="text2">Before that you can have a look at DMPE <span class="highlight">AI Hub</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        company: "",
        countryCode: "+1",
        phone: "",
      },
      options: ["AI Hub", "SC Hub", "Web Traffic"],
      selectedOption: "",
      submitted: false,
    };
  },
  methods: {
    handleOptionClick(option) {
      this.selectedOption = option;
    },
    submitForm() {
      // Submit hidden form to Google Forms
      this.$refs.hiddenForm.submit();

      // Mark form as submitted
      this.submitted = true;
    },
  },
};
</script>

<style scoped>
body {
  font-family: 'Poppins', sans-serif;
  margin-left:1 ;
  padding: 20px;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  text-align: left;
}
.align{
  margin: 3%;
}

.text-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding-top: 40px;
}

.background-text {
  font-size: 120px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #d3d3d3;
  position: absolute;
  z-index: 1;
  transform: scaleX(2) scaleY(0.6);
  transform-origin: center;
  background: linear-gradient(to bottom, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.1) 60%, rgba(211, 211, 211, 0) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.foreground-text {
  font-size: 40px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #333;
  z-index: 2;
  letter-spacing: 3px;
  padding-bottom: 0px;
}

form {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 800px;
  height: 100%;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 40px;
}

.form-row label {
  font-size: 1.8vw;
  color: #333;
  font-weight: bold;
}

.form-row input {
  border: none;
  border-bottom: 2px solid rgb(230, 75, 40, 89);
  background-color: transparent;
  padding: 20px;
  padding-left: 40px;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 2vw;
  outline: none;
  margin-left: 0px;
}

.form-row input::placeholder {
  color: rgba(166, 166, 166, 0.75);
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 1.8vw;
}

.form-row input:hover::placeholder,
.form-row input:focus::placeholder {
  opacity: 0;
}

.country-code {
  flex: 3;
  border: none;
  border-bottom: 2px solid rgb(230, 75, 40, 89);
  background-color: transparent;
  padding: 10px;
  border-radius: 0;
  font-size: 2vw;
  box-sizing: border-box;
  outline: none;
}

.country-code::placeholder {
  color: rgba(166, 166, 166, 0.75);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.country-code:hover::placeholder,
.country-code:focus::placeholder {
  opacity: 0;
}

.button-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.button-row button {
  flex: 1;
  font-size: 2vw;
  padding: 10px 20px;
  background-color: #eeeeee;
  color: #727272;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 2px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button-row button.selected {
  background-color: #ff6600;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.button-row button:hover {
  background-color: #ffffff;
  color: black;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.submit-row {
  margin-top: 20px;
}

.submit-row button {
  font-size: 2.5vw;
  padding: 10px 30px;
  background-color: rgb(230, 75, 40, 89);
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.submit-row button:hover {
  background-color: tomato;
}

.side-heading {
  font-size: 2vw;
  margin-top: 20px;
}

.submitted-content {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  height: 500px;
}

.submitted-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
  margin-left: 70px;
}

.texts-wrapper {
  display: flex;
  flex-direction: column;
}

.text1 {
  font-size: 35px;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Poppins',sans-serif;
}

.text2 {
  font-size: 20px;
  color:rgba(166, 166, 166, 0.75);
  font-family: 'Poppins',sans-serif;
}

.highlight {
  color: #ff6600;
  font-size: 20px;
  font-family: 'Poppins',sans-serif;
}

.foreground-text {
  font-size: 40px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #333;
  z-index: 2;
  letter-spacing: 3px;
  padding-bottom: 0px;
}

</style>
