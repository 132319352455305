<template>
  <div>

    <div class="text-wrapper">
      <span class="background-text">About Us</span>
      <span class="foreground-text"> A b o u t   U s </span>
    </div>

    <div class="container1">
      <div class="content1">
        <h1>Our Mission</h1>
        <img src="@/assets/women.png" alt="img" class="img1" />
        <img src="@/assets/women1.png" alt="img" class="img2" />
        <p class="hidden-paragraph">
          Our Commitment Translates into maximum ROI, 
          <br>guaranteeing the quality of traffic that has higher 
          <br>potential in terms of conversion rates and becoming 
          <br>a community of loyal customers. we excel in brand 
          <br>building, accelerating your reach, and contributing to 
          <br>long-tern sustainability. With us, you experience 
          <br>brand awareness and visibility that extends beyond 
          <br>trends and algorithm updates. Our effects and results 
          <br>are not just momentary successes, they stand 
          <br>resilient against the tides of ever-changing digital 
          <br>landscapes. We see ourselves as contributors to your 
          <br>digital growth and success stories.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextWithFadingEffect'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

body {
  font-family: 'Poppins' sans-serif;
  margin: 0; 
  padding: 20px;
  height:100%;
  width: 100%;
  background-color: #f0f0f0;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: flex-start; 
  overflow: hidden;
}

.text-wrapper {
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  padding-top: 40px; 
}

.background-text {
  font-size: 120px; 
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #d3d3d3; 
  position: absolute; 
  z-index: 1; 
  transform: scaleX(2) scaleY(0.6); 
  transform-origin: center; 
  background: linear-gradient(to bottom, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.1) 60%, rgba(211, 211, 211, 0) 100%);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent; 
  }


.foreground-text {
  font-size: 40px; 
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #333; 
  z-index: 2; 
  letter-spacing: 3px;
  padding-bottom: 0px;;
}

.container1 {
  display: flex;
  align-items: center;
  justify-content: center; 
  position: relative;
  flex-direction: column; 
  margin: 60px 20px; 
  padding-top: 40px;
  height: 100%; 
  font-family: 'Poppins', sans-serif;
}

.content1 {
  display: flex;
  align-items: center;
  text-align: justify;
  justify-content: center; 
  gap: 400px; 
  position: relative; 
  transition: gap 1.5s ease-out; 
}

img {
  max-width: 500px; 
  width: 100%;
  height: auto;
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  position: absolute; 
}

.img1 {
  opacity: 1; 
  transform: translateX(0); 
}

.img2 {
  opacity: 0; 
  transform: translateX(0); 
}

h1 {
  color: gray;
  padding-bottom: 80px;
  margin: 0;
  opacity: 1; 
  transform: translate(0, 0); 
  transition: transform 1.5s ease-out, color 0.3s ease-in-out; 
  font-size: 3em; 
  font-family: 'Poppins', sans-serif;
}

.hidden-paragraph {  
  opacity: 0;   
  transform: translateX(100px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; 
  font-size: 1.2rem; 
  text-align: justify;  
  font-family: 'Poppins', sans-serif;
}


.container1:hover {
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
}

.container1:hover .content1 {
  gap: 10px; 
}

.container1:hover h1 {
  color: black;
  transform: translate(340px, -200px); 
}

.container1:hover .img1 {
  opacity: 0; 
  transform: translateX(-400px); 
}

.container1:hover .img2 {
  opacity: 1; 
  transform: translateX(-400px); 
}

.container1:hover .hidden-paragraph {  
  opacity: 1;  
  transform: translateX(50px); 
  font-family: 'Poppins', sans-serif;
    line-height: 30px;
    text-align: justify;
    text-decoration: #30353D;
    margin-bottom: 20px;
  
}
</style>